import React from 'react';
import {ReactSVG} from 'react-svg';
import DialogClose from './DialogClose';
import type {DialogCloseProps} from './DialogClose';

export interface DialogHeaderProps extends DialogCloseProps {
  title?: string;
  iconSrc?: string;
}

const DialogHeader = (props: DialogHeaderProps) => {
  const {title, iconSrc, ...dialogCloseProps} = props;

  return (
    <div className="dialog--header">
      {
        Boolean(title) ? (
          <div className="flex text-gray-800 font-title font-bold text-lg leading-6 m-0">
            {Boolean(iconSrc) && (
              <span className="dialog--header__icon">
                <ReactSVG src={iconSrc as string} wrapper="span" />
              </span>
            )}
            <span className="dialog--header__title">{title}</span>
          </div>
        ) : (
          <div />
        ) // to keep the header consistent
      }
      <DialogClose {...dialogCloseProps} />
    </div>
  );
};

export default DialogHeader;
