import {PHONE_NUMBER} from '../../../libs/constants';

export const DEFAULT_TOP_PANEL_PROPS = {
  ergeonUrl: 'https://www.ergeon.com/',
  fencequotingUrl: 'https://fencequoting.com/',
  pdfMode: false,
  showNavigation: true,
  projectsName: 'Project Showcase',
  UTMquery: '',
  widthClass: 'wrapper-1180',
  phoneNumber: PHONE_NUMBER,
};
